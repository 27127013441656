<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
               <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Cupons de Desconto
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <ButtonAddNew route="cadastro-cupom" />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            sort-by="id"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 170"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                        >
                            <template v-slot:item.valor="{ item }">
                                <span style="color: green;">R$&nbsp;{{ formatPrice(+item.valor) }}</span>
                            </template>
                            <template v-slot:item.percentual="{ item }">
                                <span>{{ formatPrice(+item.percentual) }}%</span>
                            </template>
                            <template v-slot:item.frete_gratis="{ item }">
                                {{ item.frete_gratis ? 'Sim' : 'Não' }}
                            </template>
                            <template v-slot:item.status="{ item }">
                                <v-switch
                                    v-model="item.status"
                                    inset
                                    hide-details
                                    dense
                                    true-value="ativo"
                                    false-value="inativo"
                                    @click.stop
                                    @change="onUpdateStatus(item)"
                                    style="margin-top: 8px;"
                                />
                            </template>
                            <template v-slot:item.link="{ item }">
                                <v-btn @click.stop="copyLink(item)" icon>
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon @click.stop="excluir(item.id)" color="red">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ButtonAddNew from '@/components/ButtonAddNew';
import { mapState } from 'vuex';

export default {
    name: 'Cupons',

    components: {
        BaseContainer,
        ButtonAddNew,
    },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        headers: [
            { text: 'Código', value: 'codigo' },
            { text: 'Nome', value: 'descricao' },
            { text: 'Valor', value: 'valor' },
            { text: 'Quantidade', value: 'quantidade' },
            { text: 'Percentual', value: 'percentual' },
            { text: 'Frete Grátis', value: 'frete_gratis' },
            { text: 'Ativo', value: 'status' },
            { text: 'Link', value: 'link', sortable: false },
            { text: 'Excluir', value: 'action', sortable: false, align: 'end' },
        ],
    }),

    mounted() {
        this.consultar();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];
            this.$http.get('cupons').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        editar(row) {
            this.$store.commit('setData', row);
            this.$router.push('/cadastro-cupom');
        },

        async copyLink(item) {
            const baseURL = (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8081/'
                : 'https://app.zapermenu.com.br/';

            const link = `${baseURL}${this.usuario.dominio}?cupom=${item.codigo}`;

            try {
                await navigator.clipboard.writeText(link);
                this.notify('Link copiado!');
            } catch(err) {
                this.notify('Não foi possivel copiar!');
            }
        },

        onUpdateStatus(cupom) {
            this.$http.put(`cupons/${cupom.id}`, cupom).then(() => {
                this.notify('Atualizado com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        async excluir(id) {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir?', { color: 'red darken-1' })) {
                this.$http.delete(`cupons/${id}`).then(() => {
                    this.notify('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                });
            }
        },
    },
}
</script>
